import React from 'react';
import '../style/ChooseVersion.css';
import { getComaPrice, makespaceline } from '../module/simple';

function ChooseVersion({ props }) {
	const datas = props.state.car.varients;
	const baseUrl = 'https://kalyanimotorsadmin.kalyanicrm.com/storage/';
	// 6 product
	//1 Color

	for (let i = 0; i < datas.length; i++) {
		if (i < datas[i].products.length) {
			datas[i]['varientColor'] = i;
		} else {
			datas[i]['varientColor'] = 0;
		}
	}

	// console.log('as', datas);
	return (
		<>
			{datas && (
				<div>
					<h1 className="mainHeading">Related Model Variants...</h1>
					<div className="mainContainer">
						{datas.map((item, idx) => {
							return item.products[item.varientColor] ? (
								<div
									className="versionContainer"
									key={idx}
									onClick={(e) =>
										(window.location =
											'/car/' +
											makespaceline(item.products[0].route + '/' + idx))
									}
								>
									<div className="imgCont">
										<img
											src={
												item.products.length > 0 && item.varientColor ? (
													baseUrl +
													item.products[item.varientColor].images[0] ? (
														baseUrl +
														item.products[item.varientColor].images[0].images
													) : (
														<h4>No Data</h4>
													)
												) : (
													baseUrl +
													item.products[item.varientColor].images[0].images
												)
											}
											alt="Kalyanimotors Leading Maruti Suzuki Dealership"
										/>
									</div>
									<div className="dataCont">
										<p className="title">{item.var_title}</p>
										<h2 className="price">
											{item.products.length > 0 &&'₹'+ getComaPrice(item.products[0].price) +' /-'}
										</h2>
										<h4 className="Ex_price">Ex-Showroom Price</h4>
									</div>
								</div>
							) : null;
						})}
					</div>
				</div>
			)}
		</>
	);
}

export default ChooseVersion;
