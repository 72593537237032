import React from "react";
import bengaluru from "../asset/BENGALURU.jpg";
import hyderabad from "../asset/HYDERABAD.jpg";
import mysore from "../asset/mysore.jpeg";
import ramanagara from "../asset/Ramanagara.jpg";
import kalyaniDark from "../asset/kalyani_dark.png";
import kandm from "../asset/m&k.png";

export default function HomeIntroArea({ props }) {
  const { locations_h, locations_b, locations_m, locations_r } = props.state;
  return (
    <div className="hm2_ln_a">
      <div className="hm2_ln_b">
        <div className="hm2_ln_c">
          <img alt="Kalyani" className="hm2_ln_d" src={kalyaniDark} />
          <img alt="Maruti" className="hm2_ln_e" src={kandm} />
        </div>
        <div className="hm2_ln_f">
          <div className="hm2_ln_g">CHOOSE YOUR CITY</div>
          <div className="hm2_ln_h">
            <div
              className="hm2_ln_i"
              onClick={() => {
                if (locations_b.length > 0) {
                  localStorage.setItem("location", "Bengaluru");
                  props.setState({ location: locations_b[0] });
                }
              }}
            >
              <img alt="Bengaluru" className="hm2_ln_j" src={bengaluru} />
              <div className="hm2_ln_k">BENGALURU</div>
            </div>

            <div
              className="hm2_ln_i"
              onClick={() => {
                if (locations_m.length > 0) {
                  localStorage.setItem("location", "Mysore");
                  props.setState({ location: locations_m[0] });
                }
              }}
            >
              <img alt="Mysore" className="hm2_ln_j" src={mysore} />
              <div className="hm2_ln_n">MYSORE</div>
            </div>

            <div
              className="hm2_ln_i"
              onClick={() => {
                if (locations_h.length > 0) {
                  // localStorage.setItem("location", "Ramanagara");
                  // props.setState({ location: locations_r[0] });
                  localStorage.setItem("location", "Hydrabad");
                  props.setState({ location: locations_h[0] });
                }
              }}
            >
              <img alt="Hyderabad" className="hm2_ln_j" src={hyderabad} />
              {/* <img alt="Hyderabad" className="hm2_ln_j" src={ramanagara} /> */}
              {/* <div className="hm2_ln_n">RAMANAGARA</div> */}
              <div className="hm2_ln_n">HYDERABAD</div>
            </div>
          </div>
          {/* <div
            className="hm2_ln_l"
            onClick={() => {
              if (locations_h.length > 0) {
                localStorage.setItem("location", "Hydrabad");
                props.setState({ location: locations_h[0] });
              }
            }}
          >
            <img alt="Hyderabad" className="hm2_ln_o" src={hyderabad} />
            <div className="hm2_ln_n">HYDERABAD</div>
          </div> */}
        </div>
        <div className="hm2_ln_p">
          COPYRIGHT © KALYANI MOTORS PVT LTD, INDIA
        </div>
      </div>
    </div>
  );
}
