import React from 'react';
import AboutUs from './screen/aboutus';
import AllCars from './screen/all_car';
import Car from './screen/car';
import ContactPage from './screen/contact';
import DynamicPage from './screen/dynamic';
import HomePage from './screen/home';
import Service from './screen/service';
import './style/all1.css';

export default function App({ props }) {
	var path = window.location.pathname.split('/')[1];
	if (path === 'service') return <Service props={props} />;
	if (path === 'car') return <Car props={props} />;
	if (path === 'allcars') return <AllCars props={props} />;
	if (path === 'contactus') return <ContactPage />;
	if (path === 'about') return <AboutUs />;
	if (path === 'dynamic') return <DynamicPage />;

	// Old Website URL Redirection...
	var redirectPath = window.location.pathname;

	if (redirectPath === '/maruti-suzuki-on-road-price')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-cars/hyderabad')
		return (window.location = '/allcars');
	if (redirectPath === '//maruti-suzuki-cars')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-cars/hyderabad?CMP=Hyderabad')
		return (window.location = '/allcars/2');
	if (redirectPath === '/compare_cars') return (window.location = '/allcars/2');
	if (redirectPath === '/nexa-xl68-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_XL6');
	if (redirectPath === '/maruti-suzuki-on-road-price/hyderabad?CMP=Hyderabad')
		return (window.location = '/allcars');
	if (redirectPath === '/nexa-cars')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/nexa-on-road-price')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/nexa-cars?CMP=NexaBLR')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/maruti-suzuki-on-road-price/hyderabad?CMP=Bangalore')
		return (window.location = '/allcars');
	if (redirectPath === '//maruti-suzuki-on-road-price/bangalore')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-new-cars')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/maruti-suzuki-next-gen-ertiga-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Ertiga/2');
	if (redirectPath === '/nexa-cars/bangalore')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/maruti-suzuki-cars?CMP=Bangalore')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-new-swift-2021-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Swift');
	if (redirectPath === '/maruti-suzuki-online-service-booking')
		return (window.location = '/service/Periodic_Services/2');
	if (redirectPath === '/maruti-suzuki-new-cars/bangalore')
		return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-s-presso2-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Spresso');
	if (redirectPath === '/maruti-suzuki-vitara-brezza-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Vitara_Brezza');
	if (redirectPath === '/maruti-suzuki-next-gen-ertiga-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Ertiga/2');
	if (redirectPath === '/my-dream-cars') return (window.location = '/allcars');
	if (redirectPath === '/maruti-suzuki-vitara-brezza-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Vitara_Brezza');
	if (redirectPath === '/maruti-suzuki-new-alto-800-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Alto/2');
	if (redirectPath === '/maruti-suzuki-new-swift-2021-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Swift/2');
	if (redirectPath === '/hyderabad') return (window.location = '/');
	if (redirectPath === '/nexa-new-baleno-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Baleno');
	if (redirectPath === '/commercial-cars/eeco-cargo')
		return (window.location = '/allcars/0/commercial');
	if (redirectPath === '/maruti-suzuki-s-presso2-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Spresso');
	if (redirectPath === '/maruti-suzuki-on-road-price?CMP=Bangalore')
		return (window.location = '/allcars');
	if (redirectPath === '/nexa-cars/hyderabad?CMP=NexaHYD')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/nexa-cars/hyderabad')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/maruti-suzuki-new-alto-800-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Alto');
	if (redirectPath === '/enquiry?CMP=NexaBLR') return (window.location = '/');
	if (redirectPath === '/nexa-xl68-gallery/hyderabad')
		return (window.location = '/car/Maruti_Suzuki_XL6');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/careers') return (window.location = '/about/careers');
	if (redirectPath === '/contact-us/service')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-new-dzire-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Swift/2');
	if (redirectPath === '/maruti-suzuki-showroom-mysore-road/service')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-cars/hyderabad?CMP=Bangalore')
		return (window.location = '/');
	if (redirectPath === '/nexa-xl68-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_XL6');
	if (redirectPath === '/about-kalyani') return (window.location = '/about');
	if (redirectPath === '/enquiry?CMP=NexaHYD') return (window.location = '/');
	if (redirectPath === '/nexa-new-ignis-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Ignis');
	if (redirectPath === '/maruti-suzuki-new-celerio1-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Celerio');
	if (redirectPath === '/nexa-s-cross1-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_S-Cross');
	if (redirectPath === '/maruti-suzuki-alto-k10-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Alto');
	if (redirectPath === '/contact-us/sales')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-omni-onroad-price')
		return (window.location = '/allcars');
	if (redirectPath === '/commercial-cars/super-carry')
		return (window.location = '/allcars/0/commercial');
	if (redirectPath === '/nexa-new-baleno-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Baleno');
	if (redirectPath === '/messenger/17c4rlhzos1bd1qlk1njulldut.html')
		return (window.location = '/');
	if (redirectPath === '/maruti-suzuki-new-dzire-onroad-price')
		return (window.location = '/allcars');
	if (redirectPath === '/nexa-baleno-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Baleno');
	if (redirectPath === '/maruti-suzuki-celerio-x-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Baleno');

	if (redirectPath === '/maruti-suzuki-s-presso2-overview')
		return (window.location = window.location = '/car/Maruti_Suzuki_Spresso');
	if (redirectPath === '/nexa-s-cross1-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_S-Cross');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-overview')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/maruti-suzuki-new-swift-2021-onroad-price/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Swift/2');
	if (redirectPath === '/maruti-suzuki-showroom-mysore-road/true_value')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-gallery')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/contact-us/corporate_office')
		return (window.location = '/about/aboutkalyani');
	if (redirectPath === '/maruti-suzuki-new-swift-2021-gallery/hyderabad')
		return (window.location = '/car/Maruti_Suzuki_Swift');
	if (redirectPath === '/0') return (window.location = '/');
	if (redirectPath === '/maruti-suzuki-showroom-kr-puram/true_value/bangalore')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-new-celerio1-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Celerio');
	if (redirectPath === '/nexa-on-road-price/hyderabad')
		return (window.location = '/allcars/0/nexa');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-emi-calculator')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/maruti-suzuki-showroom-lb-nagar/sales')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-next-gen-ertiga-gallery')
		return (window.location = '/car/Maruti_Suzuki_Ertiga');
	if (redirectPath === '/nexa-new-ignis-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Ignis');
	if (redirectPath === '/nexa-ignis-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Ignis');
	if (redirectPath === '/maruti-suzuki-next-gen-ertiga-overview')
		return (window.location = '/car/Maruti_Suzuki_Ertiga');
	if (redirectPath === '/feedback') return (window.location = '/');
	if (redirectPath === '/maruti-suzuki-vitara-brezza-emi-calculator')
		return (window.location = '/car/Maruti_Suzuki_Vitara_Brezza');
	if (redirectPath === '/careers-at-kalyani')
		return (window.location = '/about/careers');
	if (redirectPath === '/maruti-suzuki-showroom-mysore-road/sales')
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-new-alto-800-emi-calculator')
		return (window.location = '/car/Maruti_Suzuki_Alto');
	if (redirectPath === '/maruti-suzuki-eeco-onroad-price')
		return (window.location = '/allcars/2');
	if (redirectPath === '/maruti-suzuki-online-service-booking/bangalore')
		return (window.location = '/service/Periodic_Services/2');
	if (
		redirectPath === '/maruti-suzuki-showroom-bannerghatta-road/sales/bangalore'
	)
		return (window.location = '/contactus');
	if (redirectPath === '/maruti-suzuki-next-gen-ertiga-emi-calculator')
		return (window.location = '/car/Maruti_Suzuki_Ertiga');
	if (redirectPath === '/maruti-suzuki-big-new-wagonr-gallery/hyderabad')
		return (window.location = '/car/Maruti_Suzuki_Wagon_R');
	if (redirectPath === '/maruti-suzuki-new-swift-2021-emi-calculator')
		return (window.location = '/car/Maruti_Suzuki_Swift');
	if (redirectPath === '/nexa-the-new-ciaz-gallery/bangalore')
		return (window.location = '/car/Maruti_Suzuki_Ciaz');
	if (redirectPath === '/maruti-suzuki-celerio-onroad-price')
		return (window.location = '/car/Maruti_Suzuki_Celerio');
	if (redirectPath === '/nexa-xl68-overview')
		return (window.location = '/car/Maruti_Suzuki_XL6');
	if (redirectPath === '/maruti-suzuki-showroom-nacharam')
		return (window.location = '/contactus');
	if (redirectPath === '/nexa-xl68-gallery')
		return (window.location = '/car/Maruti_Suzuki_XL6');
	if (redirectPath === '/awards-and-achievements')
		return (window.location = '/about');
	if (redirectPath === '/virtual_showroom')
		return (window.location = '/contactus');
	if (redirectPath === '/finance-process-documents')
		return (window.location = '/');
	if (redirectPath === '/enquiry') return (window.location = '/');
	if (
		redirectPath === '/uploads/model/full/11f501fcd26636a347e51c3654da1011.pdf'
	)
		return (window.location =
			'/car/Maruti_Suzuki_Wagon_R/1');
		if (
			redirectPath ===
			'/uploads/model/full/bc6334a825a9af5e125abe09a56c1c40.pdf'
		)
			return (window.location = '/allcars/0/0/Maruti_Suzuki_Ciaz');	
	if (
		redirectPath ===
		'/demo/application/themes/maruti/assets/img/commercial/pdf/Diesel-Brochure.pdf'
	)
		return (window.location = '/allcars/0/0/Maruti_Suzuki_Super_Carry');			
			

	return <HomePage props={props} />;
}
