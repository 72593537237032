import React, { useState, useEffect } from 'react';

function CarPhotoDesc({ props }) {
	const [imgUrl, setImgUrl] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	const { car, versionCount } = props.state;
	const datas = car.varients;
	

	useEffect(() => {
		if (car.varients[versionCount].interior_images.length > 0) {
			// console.log(
			// 	'selected',
			// 	car.varients[versionCount].interior_images[0].interior_images
			// );
			setImgUrl(
				'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
					car.varients[versionCount].interior_images[0].interior_images
			);
			setTitle(car.varients[versionCount].interior_images[0].title);
			setDescription(
				car.varients[versionCount].interior_images[0].int_description
			);
			// }
		}
	}, [versionCount]);

	return (
		<>
			{datas[versionCount].interior_images.length > 0 ? (
				<div className="cr2_pd_a">
					<div className="cr_1e">Interior design</div>
					<div className="cr2_pd_b">
						<div className="cr2_pd_g">
							{datas[versionCount].interior_images.map((item, k) => (
								<div key={k}>
									<img
										// key={k}
										alt="Kalyani Motors Leading Maruti Suzuki Dealership"
										src={
											'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
											item.interior_images
										}
										className="cr2_pd_h"
										onClick={() => [
											setTitle(item.title),
											setImgUrl(
												'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
													item.interior_images
											),
											setDescription(item.int_description),
										]}
									/>
								</div>
							))}
						</div>
						<div className="d_cont">
							<img
								alt="Kalyani Motors Leading Maruti Suzuki Dealership"
								className="cr2_pd_c"
								src={imgUrl}
							/>
							<div className="cr2_pd_d">
								<div className="cr2_pd_e">{title}</div>
								<div
									className="cr2_pd_f"
									style={{ textAlign: 'justify', marginBottom: '15px' }}
								>
									{description}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);

	// return (
	// 	<>
	// 		{datas[versionCount].interior_images.length > 0 ? (
	// 			<>
	// 				{datas.map((list, idx) => {
	// 					return list.interior_images.length > 0 ? (
	// 						<div key={idx}>
	// 							<div className="cr2_pd_a">
	// 								<div className="cr_1e">Interior design</div>
	// 								<div className="cr2_pd_b">
	// 									<div className="cr2_pd_g">
	// 										{list.interior_images.map((item, k) => (
	// 											<div key={k}>
	// 												<img
	// 													// key={k}
	// 													alt="Kalyani Motors Leading Maruti Suzuki Dealership"
	// 													src={
	// 														'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
	// 														item.interior_images
	// 													}
	// 													className="cr2_pd_h"
	// 													onClick={() => [
	// 														setTitle(item.title),
	// 														setImgUrl(
	// 															'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
	// 																item.interior_images
	// 														),
	// 														setDescription(item.int_description),
	// 													]}
	// 												/>
	// 											</div>
	// 										))}
	// 									</div>
	// 									<div className="d_cont">
	// 										<img alt="Kalyani Motors Leading Maruti Suzuki Dealership" className="cr2_pd_c" src={imgUrl} />
	// 										<div className="cr2_pd_d">
	// 											<div className="cr2_pd_e">{title}</div>
	// 											<div
	// 												className="cr2_pd_f"
	// 												style={{ textAlign: 'justify', marginBottom: '15px' }}
	// 											>
	// 												{description}
	// 											</div>
	// 										</div>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 					) : null;
	// 				})}
	// 			</>
	// 		) : null}
	// 	</>
	// );
}
export default CarPhotoDesc;
