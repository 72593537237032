import React from 'react';
import { getServiceImage } from '../module/api_init';
import { makespaceline } from '../module/simple';

export default function HomeService({ props }) {
	const { services, location } = props.state;

	return (
		<div className="hb_1a" id="hm_c">
			<div className="hb_1d" />
			<div className="hb_1b">
				<div className="hb_1b_a"> Car Services And Maintenance </div>
			</div>
			<div className="hb_1b"></div>
			<div className="hb_1c">
				Get all your car needs i.e. repairs, maintenance, body repairs, cashless
				insurance claims insurance renewals, accessories, car care etc., under a
				single roof of Kalyani Service Centres across silicon valley of India –
				Bengaluru City.
				{/* Get discounted professional periodic car service and repair, wheel care,
        cashless insurance claim and much more in the Silicon Valley of India &nbsp; */}
				{localStorage.getItem('location')}.
			</div>
			<div className="home_services">
				{services.map((d, k) => (
					<div
						key={k}
						className="home_services_each"
						onClick={() =>
							(window.location =
								'/service/' + makespaceline(d.title) + '/' + location.id)
						}
					>
						<div className="home_services_img_area">
							<img
								className="home_services_each_img"
								src={getServiceImage(d.image)}
								alt="Kalyanimotors Leading Maruti Suzuki Dealership"
							/>
						</div>
						<div className="home_services_each_title">{d.title}</div>
						<div className="home_services_each_subtitle">{d.description}</div>
					</div>
				))}
			</div>
		</div>
	);
}
