import axios from "axios";

export async function getDynamicDate(props) {
  var path = window.location.pathname.split("/")[2];
  const url = "https://kalyanimotorsapi.kalyanicrm.com/api/";
  await Promise.all([
    axios.get(url + "blogpage"), //  0
    axios.get(url + "iconlocation"), //  1
  ]).then((res) => {
    var locations_h = [];
    var locations_b = [];
    var locations_m = [];
    var locations_r = [];
    for (let i = 0; i < res[1].data.length; i++) {
      const element = res[1].data[i];
      if (element.city === 1) locations_b.push(element);
      if (element.city === 2) locations_m.push(element);
      else locations_h.push(element);
    }

    var location = localStorage.getItem("location");
    if (location === "Hydrabad" && locations_h.length > 0)
      location = locations_h[0];
    if (location === "Mysore" && locations_m.length > 0)
      location = locations_h[0];
    if (location === "Ramanagara" && locations_r.length > 0)
      location = locations_h[0];
    if (location === "Bengaluru" && locations_b.length > 0)
      location = locations_h[0];

    for (let i = 0; i < res[0].data.length; i++) {
      const element = res[0].data[i];
      if (element.title === path) {
        props.setState({ dynamic_link: element.blogpage_link });
        break;
      }
    }
    props.setState({
      dynamic_links: res[0].data,
      location,
      locations_h,
      locations_b,
      locations_m,
    });
  });
}
