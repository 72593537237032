import React, { useEffect } from 'react';
import { kalyaniDark } from '../module/logo';
// import arrow_down from "../asset/arrow_down.svg";
import close_1 from '../asset/close_1.svg';
import { getImage } from '../module/api_init';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import phoneIcon from '../asset/phone_icon.png';
import maruthiLogo from '../asset/marutiLogo2.png';
import maruthiLogo2 from '../asset/maruthiLogo.png';
import ReactPlayer from 'react-player';

export function HomeLocationPopup({ props }) {
	const { locations_h, locations_b, locationPopup, locations_m } = props.state;
	return (
		<div
			style={locationPopup ? { visibility: 'visible' } : {}}
			className="mylocation_popup_screen"
		>
			<div className="mylocation_popup">
				<div className="row">
					<div style={{ width: '100%' }}>
						<div className="mylocation_popup_title">Choose Your City</div>
						<div className="mylocation_popup_subtitle">
							This would help us in providing you quick service.
						</div>
					</div>
					<img
						width="20"
						alt="Kalyanimotors Leading Maruti Suzuki Dealership"
						style={{ cursor: 'pointer' }}
						onClick={() => props.setState({ locationPopup: false })}
						src={close_1}
					/>
				</div>
				<div className="mylocation_popup_subtitle2">Bangalore</div>
				<div className="mylocation_popup_body">
					{locations_b.map((item, k) => (
						<div
							key={k}
							className="mylocation_each"
							onClick={() =>
								props.setState({ location: item, locationPopup: false })
							}
						>
							<img
								width="50"
								src={getImage(item.loc_image)}
								alt="Kalyanimotors Leading Maruti Suzuki Dealership"
							/>

							<div>{item.title}</div>
						</div>
					))}
				</div>
				<div className="mylocation_popup_subtitle2">Hydrabad</div>
				<div className="mylocation_popup_body">
					{locations_h.map((item, k) => (
						<div
							key={k}
							className="mylocation_each"
							onClick={() =>
								props.setState({ location: item, locationPopup: false })
							}
						>
							<img
								width="50"
								src={getImage(item.loc_image)}
								alt="Kalyanimotors Leading Maruti Suzuki Dealership"
							/>
							<div>{item.title}</div>
						</div>
					))}
				</div>
			</div>

			<div className="mylocation_popup_subtitle2">Mysore</div>
      <div className="mylocation_popup_body">
        {locations_m.map((item, k) => (
          <div
            key={k}
            className="mylocation_each"
            onClick={() =>
              props.setState({ location: item, locationPopup: false })
            }
          >
            <img width="50" src={getImage(item.loc_image)} alt="Kalyanimotors Leading Maruti Suzuki Dealership" />
            <div>{item.title}</div>
          </div>
        ))}
      </div>
		</div>
	);
}

export function Header({ props }) {
	const { location } = props.state;
	const { locations_h, locations_b, locations_m, locations_r } = props.state;


	useEffect(() => {
		if (
			localStorage.getItem('location') === 'Hydrabad' &&
			locations_h.length > 0
		) {
			props.setState({ location: locations_h[0] });
			localStorage.setItem('location', 'Hydrabad');
		}
		if (
			localStorage.getItem('location') === 'Bengaluru' &&
			locations_b.length > 0
		) {
			props.setState({ location: locations_b[0] });
			localStorage.setItem('location', 'Bengaluru');
		}
		if (
			localStorage.getItem('location') === 'Mysore' &&
			locations_m.length > 0
		) {
			props.setState({ location: locations_m[0] });
			localStorage.setItem('location', 'Mysore');
		}
	}, []);

	return (
		<React.StrictMode>
			<div className="hm1_hd_a">
				<div className="home_base_logo_area">
					<img
						loading="lazy"
						src={kalyaniDark}
						className="home_base_logo"
						onClick={() => (window.location = '/')}
						alt="Kalyani"
					/>
					<select
						className="location_text"
						value={localStorage.getItem('location')}
						onChange={(e) => {
							var location = e.target.value;						
							if (location === 'Hydrabad' && locations_h.length > 0) {
								props.setState({ location: locations_h[0] });
								localStorage.setItem('location', 'Hydrabad');
							}
							if (location === 'Bengaluru' && locations_b.length > 0) {
								props.setState({ location: locations_b[0] });
								localStorage.setItem('location', 'Bengaluru');
							}
							if (location === 'Mysore' && locations_m.length > 0) {
								props.setState({ location: locations_m[0] });
								localStorage.setItem('location', 'Mysore');
							}
							// if (location === "Ramanagara" && locations_r.length > 0) {
							//   props.setState({ location: locations_r[0] });
							//   localStorage.setItem("location", "Ramanagara");
							// }
						}}
					>
						<option value="Hydrabad">Hyderabad</option>
						<option value="Bengaluru">Bengaluru</option>
						<option value="Mysore">Mysore</option>
						{/* <option value="Ramanagara">Ramanagara</option> */}
					</select>
					{/* <div
            onClick={() => props.setState({ locationPopup: true })}
            className="location_text"
          >
            {locName}
            <img alt="Kalyanimotors Leading Maruti Suzuki Dealership" loading="lazy" src={arrow_down} />
          </div> */}
				</div>
				<div className="header_container_menu">
					<div
						className="header_text"
						onClick={() =>
							window.location.assign('tel:' + (location.phone_number ?? ''))
						}
					>
						<img
							alt="Kalyani Motors Leading Maruti Suzuki Dealership"
							src={phoneIcon}
							className="headphone_icon"
						/>

						{location != null ? location.phone_number : null}
					</div>
					<img
						width="45"
						alt="Kalyani Motors Leading Maruti Suzuki Dealership"
						src={maruthiLogo}
						className="logo_icon1"
					/>
				</div>
			</div>
			<div className="hm1_mo_hd_a">
				<img
					loading="lazy"
					src={kalyaniDark}
					className="hm1_mo_hd_b"
					onClick={() => (window.location = '/')}
					alt="Kalyani"
				/>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div
						className="header_text"
						onClick={() =>
							window.location.assign('tel:' + (location.phone_number ?? ''))
						}
					>
						<img
							className="headphone_icon"
							alt="Kalyani Motors Leading Maruti Suzuki Dealership"
							src={phoneIcon}
							width="15"
						/>
						{location != null ? location.phone_number : null}
					</div>
					<img
						alt="Kalyani Motors Leading Maruti Suzuki Dealership"
						src={maruthiLogo2}
						className="logo_icon1"
					/>
				</div>
			</div>

			{/* Google Script pasted by arul */}
			{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=UA-33586910-1"
			></script>
			<script>
				{`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
       gtag('config', 'UA-33586910-1');`}
			</script>

			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=G-7QVT44T9Z3"
			></script>
			<script>
				{`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-7QVT44T9Z3');`}
			</script>

			{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}

			{/* <!-- Global site tag (gtag.js) - Google Ads -->  */}
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=AW-1014248716"
			></script>
			<script>
				{`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'AW-1014248716');`}
			</script>

			{/* <!-- Global site tag (gtag.js) - Google Ads -->  */}

			{/* <!-- Facebook Pixel Code --> */}
			<script>
				{` !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '539229427481212'); 
    fbq('track', 'PageView'); `}
			</script>
			<noscript>
				<img
					height="1"
					width="1"
					src="https://www.facebook.com/tr?id=539229427481212&ev=PageView
&noscript=1"
					alt="Kalyanimotors Leading Maruti Suzuki Dealership"
				/>
			</noscript>
			{/* <!-- End Facebook Pixel Code --> */}

			{/* Google Script pasted by arul - END */}
		</React.StrictMode>
	);
}

export function HomeTop({ props }) {
	const { banners, location } = props.state;

	if (location === null) return '';
	const _banners =
		location.title.toLowerCase() === 'hyderabad'
			? banners.hyderabad
			: banners.bengaluru;

	return (
		<div className="hm1_a">
			<div className="hm1_b">
				<Carousel
					autoPlay={true}
					infiniteLoop={true}
					showArrows={true}
					showThumbs={false}
				>
					{_banners.map((b, k) => {
						return (
							<div key={k}>
								{/* {console.log(b.banner_img.split('.').pop() === 'jpg')} */}
								{/* {console.log(b)}
								{console.log(b.banner_img)} */}
								{b.banner_img.split('.').pop() === 'jpg' ? (
									<img
										alt="Kalyani Motors Leading Maruti Suzuki Dealership"
										className="hm1_d"
										src={getImage(b.banner_img)}
									/>
								) : (
									<ReactPlayer
										url={getImage(b.banner_img)}
										loop={true}
										height="100%"
										width="100%"
										playing={true}
									/>
								)}
							</div>
						);
					})}
				</Carousel>
			</div>

			{/* <div className="car_setting_popup">
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_title">
        Find Your Car or Car Service
        </div>
        </div>
        <form
        onChange={props.carAndServiceSearch}
        onSubmit={props.carAndServiceSearch}
        >
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_body">
        <select id="type" className="car_setting_popup_type_input">
        <option value="c">New Car</option>
        <option value="s">Service</option>
        </select>
        <div className="hm1_e">
        <input
        id="value"
        className="car_setting_popup_car_input"
        placeholder="SELECT YOUR CAR"
        autoComplete="off"
        />
        {homeSearchs.length !== 0 ? (
                  <div className="hm1_c">
                  {homeSearchs.map((hs, k) => (
                    <div
                    key={k}
                    className="hm1_f"
                    onClick={() => {
                      if (hs.title === null)
                      window.location =
                      "/car/" + makespaceline(hs.route ?? "");
                      else
                      window.location =
                      "/service/" + makespaceline(hs.title ?? "");
                    }}
                      >
                        {hs.name ?? hs.title}
                        </div>
                        ))}
                        </div>
                        ) : null}
                        </div>
              <button type="submit" className="car_setting_popup_button">
              Check Now
              </button>
              </div>
              </div>
        </form>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_subtitle">
            <div className="row">Get instant quotes for your car service</div>
            <div style={{ cursor: "pointer" }} className="row">
              <div onClick={() => props.executeScroll("hm_c")}>
              Our Services
              </div>
              &nbsp;|&nbsp;
              <div onClick={() => props.executeScroll("hm_g")}>About Us</div>
            </div>
          </div>
          </div>
        </div> */}
		</div>
	);
}
